<script setup lang="ts">
import { ref, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router/composables'
import ui from '/~/core/ui'
import { sleep } from '/~/utils/timer'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import DrawerIconHeader from '/~/components/drawer/components/drawer-icon-header.v2.vue'
import { usePurchases } from '/~/composables/purchases'

const props = withDefaults(
  defineProps<{
    onSubmit?: () => void
  }>(),
  {
    onSubmit: () => {},
  }
)

const emit = defineEmits<{
  (event: 'hide'): void
}>()

const router = useRouter()
const route = useRoute()

const { unlockGiftCard, selectedPurchaseCard } = usePurchases()

const loading = ref(false)
const unlocked = ref(false)

const cardRoute = computed(() =>
  route.meta?.itemRoute
    ? `${route.meta.itemRoute}/${(selectedPurchaseCard.value as any).uuid}`
    : {
        hash: `#profile-e-purchase/${(selectedPurchaseCard.value as any).uuid}`,
      }
)

async function submit() {
  if (loading.value || !selectedPurchaseCard.value) {
    return
  }

  loading.value = true

  await unlockGiftCard((selectedPurchaseCard.value as any).uuid)

  await sleep(800).then(() => {
    props.onSubmit()
    emit('hide')

    loading.value = false
    unlocked.value = true

    if (route.name !== 'e-purchase') {
      router.push(cardRoute.value)
    }
  })
}

async function cancel() {
  emit('hide')
}

const swiperIcon = computed(() => {
  let icon = ''

  if (loading.value) {
    icon = 'plain/loader'
  } else if (unlocked.value) {
    icon = 'v2/box-icons/bx-lock-open'
  } else {
    icon = 'v2/box-icons/bx-lock-alt'
  }

  return icon
})

const clickToUnlock = computed(() => {
  return `${ui.mobile ? 'Tap' : 'Click'} To Unlock`
})
</script>

<template>
  <div
    class="flex max-h-full grow flex-col space-y-2.5 overflow-hidden bg-light"
  >
    <drawer-icon-header title="Unlock your ePurchase" :action="cancel">
      <template #icon>
        <div
          class="flex h-[120px] w-[120px] shrink-0 items-center justify-center rounded-full bg-primary p-[15px] text-white"
        >
          <base-icon svg="v2/box-icons/bx-lock" size="auto" />
        </div>
      </template>
    </drawer-icon-header>

    <div
      class="flex w-full items-center justify-center overflow-y-auto px-5 pb-[30px]"
    >
      <button
        class="relative mt-2 flex h-14 w-80 max-w-full items-center justify-center overflow-hidden rounded-full border border-zinc-100"
        :class="[
          !loading && !unlocked && 'border-divider',
          loading && 'border-blue-300 bg-blue-400',
          unlocked && 'border-success bg-fg-success',
        ]"
        @click="submit"
      >
        <span
          class="absolute left-0 block w-full transform transition duration-300"
          :class="[
            loading || unlocked
              ? '-ml-12 translate-x-full'
              : 'translate-x-[5px]',
          ]"
        >
          <span
            class="flex h-11 w-11 items-center justify-center rounded-full text-white"
            :class="[
              loading && 'animate-spin',
              !unlocked && !loading && 'bg-primary',
              unlocked && !loading && 'bg-fg-success',
            ]"
          >
            <base-icon :svg="swiperIcon" size="md" />
          </span>
        </span>

        <span
          class="block font-bold"
          :class="[loading || unlocked ? 'text-white' : 'text-primary']"
        >
          <span v-if="unlocked">Unlocked</span>
          <span v-else-if="!loading">{{ clickToUnlock }}</span>
        </span>
      </button>
    </div>
  </div>
</template>
